import * as Yup from 'yup';
import { TFunction } from 'react-i18next';
import { object, string } from 'yup';

export interface IAkcjaGorzkaValues {
    tried: boolean | undefined;
    kind1: boolean;
    kind2: boolean;
    triedOption1: boolean;
    triedOption2: boolean;
    triedOption3: boolean;
    triedAnswer: string;
    answer: string;
    files: [];
    position: 'owner' | 'manager' | 'seller' | '';
    firstname: string;
    lastname: string;
    email: string;
    phonePrefix: string;
    phone: string;
    cityId: number | null;
    zipCode: string;
    street: string;
    apartmentNumber: string;
    shopId: number | null;
    rules1: boolean;
    rules2: boolean;
}

export const initialValues: IAkcjaGorzkaValues = {
    tried: undefined,
    kind1: false,
    kind2: false,
    triedOption1: false,
    triedOption2: false,
    triedOption3: false,
    triedAnswer: '',
    answer: '',
    files: [],
    position: '',
    firstname: '',
    lastname: '',
    email: '',
    phonePrefix: '+48',
    phone: '',
    cityId: null,
    zipCode: '',
    street: '',
    apartmentNumber: '',
    shopId: null,
    rules1: false,
    rules2: false,
};

export const AKCJA_GORZKA_MAX_ANSWER_LENGTH = 500;

// Tylko cyfry od 0 do 9, równo 9 cyfr
const phoneRegExp = /^[0-9]{9}$/;

export function getValidationSchema(t: TFunction<string | string[]>) {
    return Yup.object().shape(
        {
            tried: Yup.boolean()
                .oneOf([false, true])
                .notOneOf([undefined], t('form.required.error')),
            kind1: Yup.boolean().oneOf([false, true]),
            kind2: Yup.boolean().oneOf([false, true]),
            triedOption1: Yup.boolean().when(
                ['tried', 'triedAnswer', 'triedOption2', 'triedOption3'],
                {
                    is: (
                        tried: boolean,
                        triedAnswer: string,
                        triedOption2: boolean,
                        triedOption3: boolean
                    ) => tried && triedAnswer === '' && !triedOption2 && !triedOption3,
                    then: Yup.boolean().oneOf([true], t('form.required.error')),
                    otherwise: Yup.boolean().oneOf([false, true]),
                }
            ),
            triedOption2: Yup.boolean().when(
                ['tried', 'triedAnswer', 'triedOption1', 'triedOption3'],
                {
                    is: (
                        tried: boolean,
                        triedAnswer: string,
                        triedOption1: boolean,
                        triedOption3: boolean
                    ) => tried && triedAnswer === '' && !triedOption1 && !triedOption3,
                    then: Yup.boolean().oneOf([true], t('form.required.error')),
                    otherwise: Yup.boolean().oneOf([false, true]),
                }
            ),
            triedOption3: Yup.boolean().when(
                ['tried', 'triedAnswer', 'triedOption1', 'triedOption2'],
                {
                    is: (
                        tried: boolean,
                        triedAnswer: string,
                        triedOption1: boolean,
                        triedOption2: boolean
                    ) => tried && triedAnswer === '' && !triedOption1 && !triedOption2,
                    then: Yup.boolean().oneOf([true], t('form.required.error')),
                    otherwise: Yup.boolean().oneOf([false, true]),
                }
            ),
            triedAnswer: Yup.string()
                .max(AKCJA_GORZKA_MAX_ANSWER_LENGTH, t('form.error.form.max.characters.error'))
                .when(['tried', 'triedOption1', 'triedOption2', 'triedOption3'], {
                    is: (
                        tried: boolean,
                        triedOption1: boolean,
                        triedOption2: boolean,
                        triedOption3: boolean
                    ) => tried && !triedOption1 && !triedOption2 && !triedOption3,
                    then: Yup.string().required(t('form.required.error')),
                    otherwise: Yup.string(),
                }),
            answer: Yup.string()
                .max(AKCJA_GORZKA_MAX_ANSWER_LENGTH, t('form.error.form.max.characters.error'))
                .required(t('form.required.error')),
            files: Yup.array()
                .of(object({ content: string(), mimeType: string(), name: string() }))
                .nullable(),
            position: Yup.string().required(t('form.required.error')),
            firstname: Yup.string().required(t('form.required.error')),
            lastname: Yup.string().required(t('form.required.error')),
            email: Yup.string().email(t('form.email.error')).required(t('form.required.error')),
            phonePrefix: Yup.string().required(t('form.required.error')),
            phone: Yup.string()
                .matches(phoneRegExp, t('form.phone.error'))
                .required(t('form.required.error')),
            cityId: Yup.string()
                .required(t('form.required.error'))
                .typeError(t('form.required.error')),
            zipCode: Yup.string().required(t('form.required.error')),
            street: Yup.string().required(t('form.required.error')),
            apartmentNumber: Yup.string(),
            shopId: Yup.string()
                .required(t('form.required.error'))
                .typeError(t('form.required.error')),
            rules1: Yup.boolean().oneOf([true], t('form.required.error')),
            rules2: Yup.boolean().oneOf([true], t('form.required.error')),
        },
        [
            ['triedOption1', 'triedOption2'],
            ['triedOption1', 'triedOption3'],
            ['triedOption2', 'triedOption3'],
            ['triedAnswer', 'triedOption1'],
            ['triedAnswer', 'triedOption2'],
            ['triedAnswer', 'triedOption3'],
        ]
    );
}
