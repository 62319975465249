import React, { useRef } from 'react';
import { graphql } from 'gatsby';
import { QueryClient, QueryClientProvider } from 'react-query';
import { StaticImage } from 'gatsby-plugin-image';

import {
    container,
    prize,
    form,
    banner,
    knight,
    left,
    right,
} from './akcja-gorzka-page.module.scss';

import { IPage } from '../models/page.model';

import AkcjaGorzkaLayout from '../layouts/akcja-gorzka-layout';
import AkcjaGorzkaPrize from '../components/organisms/akcja-gorzka-prize';
import AkcjaGorzkaForm from '../components/organisms/akcja-gorzka-form';

interface IAkcjaGorzkaPagePageProps {
    readonly data: {
        page: IPage | null;
    };
}

const knightImg = '../../src/assets/images/akcja-gorzka/knight.png';
const leftImg = '../../src/assets/images/akcja-gorzka/left.png';
const rightImg = '../../src/assets/images/akcja-gorzka/right.png';

const AkcjaGorzkaPage: React.FC<IAkcjaGorzkaPagePageProps> = ({ data }) => {
    const { page } = data;
    const containerRef = useRef<HTMLDivElement>(null);

    const bannerOnClick = () => {
        containerRef.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
    };

    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <AkcjaGorzkaLayout
                page={page}
                className={container}
                bannerOnClick={bannerOnClick}
                bannerClass={banner}
            >
                <StaticImage src={knightImg} alt={''} className={knight} />
                <StaticImage src={leftImg} alt={''} className={left} />
                <StaticImage src={rightImg} alt={''} className={right} />
                <div className={prize}>
                    <AkcjaGorzkaPrize showTitle={true} />
                </div>
                <div ref={containerRef} className={form}>
                    <AkcjaGorzkaForm />
                </div>
            </AkcjaGorzkaLayout>
        </QueryClientProvider>
    );
};

export const query = graphql`
    query ($language: String!, $site: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        page: strapiPage(
            locale: { eq: $language }
            site: { eq: $site }
            isDummyContent: { eq: false }
        ) {
            ...pageFields
        }
    }
`;

export default AkcjaGorzkaPage;
