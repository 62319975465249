import React, { useRef, useState } from 'react';
import ReactSelect, { InputActionMeta } from 'react-select';

import {
    Form,
    Formik,
    Field,
    ErrorMessage,
    FormikConfig,
    FormikHelpers,
    FormikProps,
} from 'formik';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { getPathFromSlug } from '../../../plugins/gatsby-plugin-ap-i18next/src/get-path-from-slug';
import { useMutation } from 'react-query';
import { navigate } from 'gatsby';

import {
    container,
    title,
    question,
    noMargin,
    optional,
    hint,
    info,
    textarea,
    input,
    questionBox,
    hiddenInputs,
    hiddenInfo,
    infoItem,
    visible,
    counter,
    fields,
    checkboxes,
    checkboxContainer,
    centered,
    checkbox,
    checkboxLabel,
    checkmark,
    button,
    error,
    loading,
    buttonRadio,
    wide,
    big,
    divider,
    buttons,
    selected,
    fileContainer,
    fileInput,
    phoneInput,
    select,
    full,
    position,
} from './akcja-gorzka-form.module.scss';

import staticFiles from '../../config/static-files';
import pagesContext from '../../config/pages-context';
import {
    getValidationSchema,
    initialValues,
    IAkcjaGorzkaValues,
    AKCJA_GORZKA_MAX_ANSWER_LENGTH,
} from '../../formik/akcja-gorzka-form.config';
import {
    akcjaGorzkaFormMutation,
    getAkcjaGorzkaCities,
    getAkcjaGorzkaShops,
} from '../../api-ssr/akcja-gorzka';

import FormCheckbox from '../atoms/form-checkbox';
import Button from '../atoms/button';
import FileInput from '../atoms/file-input';
import FormField from '../atoms/form-field';
import HandleFormikChange from '../hoc/handle-formik-change';

interface IAkcjaGorzkaFormProps {
    className?: string;
}

const MAX_FILE_SIZE = 26214400;

const AkcjaGorzkaForm: React.FC<IAkcjaGorzkaFormProps> = ({ className = '' }) => {
    const { t, language } = useI18next();
    const [isLoading, setIsLoading] = useState(false);
    const { mutateAsync: submitForm } = useMutation(akcjaGorzkaFormMutation);
    const { mutateAsync: getCities } = useMutation(getAkcjaGorzkaCities);
    const { mutateAsync: getShops } = useMutation(getAkcjaGorzkaShops);
    const formRef = useRef<FormikProps<IAkcjaGorzkaValues>>(null);
    const [selectedCity, setSelectedCity] = useState<number>();
    const [cities, setCities] = useState([]);
    const [shops, setShops] = useState([]);

    const handleSubmit: FormikConfig<IAkcjaGorzkaValues>['onSubmit'] = async (values) => {
        setIsLoading(true);
        try {
            await submitForm(values);
            const resultsUrl = getPathFromSlug(pagesContext.akcjaGorzkaThankYou.slug, language);
            navigate(resultsUrl);
        } catch (err: any) {
            const error = { ...err }.response;
            if (
                'status' in error &&
                typeof error.status === 'number' &&
                error.data &&
                typeof error.data === 'object' &&
                'messages' in error.data &&
                Array.isArray(error.data.messages)
            ) {
                alert(error.data.messages[0].content);
            } else {
                alert(error);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleAnswerTriedClick = (value: boolean, helpers: FormikHelpers<IAkcjaGorzkaValues>) => {
        helpers.setFieldValue('tried', value);
    };

    const handlePositionClick = (value: string, helpers: FormikHelpers<IAkcjaGorzkaValues>) => {
        helpers.setFieldValue('position', value);
    };

    const handleFormikChange = (values: IAkcjaGorzkaValues) => {
        if (values.phone.toString().length > 9) {
            formRef.current?.setFieldValue('phone', values.phone.toString().slice(0, 9));
        }

        if (values.cityId && values.cityId !== selectedCity) {
            populateShopsOptions(values.cityId);
            setSelectedCity(values.cityId);
        }
    };

    const popuplateCitiesOptions = (value: string, _: InputActionMeta) => {
        if (value.length > 2) {
            try {
                getCities(value).then((response) => {
                    setCities(response.data);
                });
            } catch {}
        }
    };

    const populateShopsOptions = (id: number) => {
        try {
            getShops(`${id}`).then((response) => {
                setShops(response.data);
            });
        } catch {}
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            innerRef={formRef}
            validationSchema={getValidationSchema(t)}
        >
            {(helpers) => (
                <Form className={`${container} ${className} ${isLoading ? loading : ''}`}>
                    <HandleFormikChange onChange={handleFormikChange} />
                    <div className={questionBox}>
                        <h1 className={title}>
                            {t('akcja.gorzka.form.title')} <br /> {t('akcja.gorzka.form.subtitle')}
                        </h1>
                        <p className={question}>{t('akcja.gorzka.form.tried.1')}</p>
                        <div>
                            <div className={buttons}>
                                <button
                                    type="button"
                                    className={`${buttonRadio} ${big} ${
                                        helpers.values.tried ? selected : ''
                                    }`}
                                    onClick={() => {
                                        handleAnswerTriedClick(true, helpers);
                                    }}
                                >
                                    {t('akcja.gorzka.form.tried.answer1')}
                                </button>
                                <button
                                    type="button"
                                    className={`${buttonRadio} ${big} ${
                                        !helpers.values.tried && helpers.values.tried !== undefined
                                            ? selected
                                            : ''
                                    }`}
                                    onClick={() => {
                                        handleAnswerTriedClick(false, helpers);
                                    }}
                                >
                                    {t('akcja.gorzka.form.tried.answer2')}
                                </button>
                            </div>
                            <ErrorMessage name="tried" className={error} component="p" />
                        </div>
                        <div className={divider} />
                        <div className={`${hiddenInputs} ${helpers.values.tried ? visible : ''}`}>
                            <p className={question}>{t('akcja.gorzka.form.kind')}</p>
                            <div className={`${checkboxContainer} ${centered}`}>
                                <FormCheckbox
                                    textClass={checkboxLabel}
                                    className={checkbox}
                                    checkmarkClass={checkmark}
                                    name="kind1"
                                    text={t('akcja.gorzka.form.kind.1')}
                                />
                                <ErrorMessage name="kind1" className={error} component="p" />
                            </div>
                            <div className={`${checkboxContainer} ${centered}`}>
                                <FormCheckbox
                                    textClass={checkboxLabel}
                                    className={checkbox}
                                    checkmarkClass={checkmark}
                                    name="kind2"
                                    text={t('akcja.gorzka.form.kind.2')}
                                />
                                <ErrorMessage name="kind2" className={error} component="p" />
                            </div>
                            <div className={divider} />
                            <p className={`${question} ${noMargin}`}>
                                {t('akcja.gorzka.form.tried.2')}
                            </p>
                            <p className={info}>{t('akcja.gorzka.form.tried.2.info')}</p>
                            <div className={checkboxContainer}>
                                <FormCheckbox
                                    textClass={checkboxLabel}
                                    className={checkbox}
                                    checkmarkClass={checkmark}
                                    name="triedOption1"
                                    text={t('akcja.gorzka.form.tried.option1')}
                                />
                                <ErrorMessage name="triedOption1" className={error} component="p" />
                            </div>
                            <div className={checkboxContainer}>
                                <FormCheckbox
                                    textClass={checkboxLabel}
                                    className={checkbox}
                                    checkmarkClass={checkmark}
                                    name="triedOption2"
                                    text={t('akcja.gorzka.form.tried.option2')}
                                />
                                <ErrorMessage name="triedOption2" className={error} component="p" />
                            </div>
                            <div className={checkboxContainer}>
                                <FormCheckbox
                                    textClass={checkboxLabel}
                                    className={checkbox}
                                    checkmarkClass={checkmark}
                                    name="triedOption3"
                                    text={t('akcja.gorzka.form.tried.option3')}
                                />
                                <ErrorMessage name="triedOption3" className={error} component="p" />
                            </div>
                            <Field
                                as="textarea"
                                type="textarea"
                                rows={5}
                                className={`${textarea} ${input}`}
                                name="triedAnswer"
                                placeholder={t('akcja.gorzka.form.tried.placeholder')}
                                maxLength={AKCJA_GORZKA_MAX_ANSWER_LENGTH}
                            />
                            <p className={counter}>
                                {helpers.values.triedAnswer.length}/{AKCJA_GORZKA_MAX_ANSWER_LENGTH}
                            </p>
                            <ErrorMessage name="triedAnswer" className={error} component="p" />
                            <div className={divider} />
                        </div>
                        <div
                            className={`${hiddenInfo} ${
                                !helpers.values.tried && helpers.values.tried !== undefined
                                    ? visible
                                    : ''
                            }`}
                        >
                            <p className={question}>{t('akcja.gorzka.form.tried.info.title')}</p>
                            <p className={infoItem}>{t('akcja.gorzka.form.tried.info.item1')}</p>
                            <p className={infoItem}>{t('akcja.gorzka.form.tried.info.item2')}</p>
                            <p className={infoItem}>{t('akcja.gorzka.form.tried.info.item3')}</p>
                            <div className={divider} />
                        </div>
                    </div>
                    <div className={questionBox}>
                        <p className={question}>{t('akcja.gorzka.form.answer')}</p>
                        <Field
                            as="textarea"
                            type="textarea"
                            rows={5}
                            className={`${textarea} ${input}`}
                            name="answer"
                            placeholder={t('akcja.gorzka.form.textarea.placeholder')}
                            maxLength={AKCJA_GORZKA_MAX_ANSWER_LENGTH}
                        />
                        <p className={counter}>
                            {helpers.values.answer.length}/{AKCJA_GORZKA_MAX_ANSWER_LENGTH}
                        </p>
                        <ErrorMessage name="answer" className={error} component="p" />
                    </div>
                    <div className={`${questionBox} ${optional}`}>
                        <p className={question}>{t('akcja.gorzka.form.files')}</p>
                        <p className={hint}>{t('akcja.gorzka.form.files.hint')}</p>
                        <FormField
                            id={'file-field'}
                            errorStyle={!!(helpers.errors.files && helpers.touched.files)}
                            hintText={
                                helpers.errors.files && helpers.touched.files
                                    ? (helpers.errors.files as string)
                                    : ''
                            }
                            className={fileContainer}
                        >
                            <FileInput
                                maxFileSize={MAX_FILE_SIZE}
                                fileTypes={[
                                    'application/pdf',
                                    'image/jpg',
                                    'image/jpeg',
                                    'image/png',
                                    'video/x-msvideo',
                                    'video/mov',
                                    'video/mp4',
                                ]}
                                className={fileInput}
                                name={'files'}
                                placeholder={t('akcja.gorzka.form.files.placeholder')}
                                type={'short'}
                            />
                        </FormField>
                        <ErrorMessage name="files" className={error} component="p" />
                    </div>
                    <div className={position}>
                        <div className={`${buttons} ${wide}`}>
                            <button
                                className={`${buttonRadio} ${wide} ${
                                    helpers.values.position === 'owner' ? selected : ''
                                }`}
                                type="button"
                                onClick={() => {
                                    handlePositionClick('owner', helpers);
                                }}
                            >
                                {t('akcja.gorzka.form.position.1')}
                            </button>
                            <button
                                className={`${buttonRadio} ${wide} ${
                                    helpers.values.position === 'manager' ? selected : ''
                                }`}
                                type="button"
                                onClick={() => {
                                    handlePositionClick('manager', helpers);
                                }}
                            >
                                {t('akcja.gorzka.form.position.2')}
                            </button>
                            <button
                                className={`${buttonRadio} ${wide} ${
                                    helpers.values.position === 'seller' ? selected : ''
                                }`}
                                type="button"
                                onClick={() => {
                                    handlePositionClick('seller', helpers);
                                }}
                            >
                                {t('akcja.gorzka.form.position.3')}
                            </button>
                        </div>
                        <ErrorMessage name="position" className={error} component="p" />
                    </div>

                    <div className={fields}>
                        <div>
                            <Field
                                className={input}
                                name="firstname"
                                placeholder={t('akcja.gorzka.form.firstname.placeholder')}
                            />
                            <ErrorMessage name="firstname" className={error} component="p" />
                        </div>
                        <div>
                            <Field
                                className={input}
                                name="lastname"
                                placeholder={t('akcja.gorzka.form.lastname.placeholder')}
                            />
                            <ErrorMessage name="lastname" className={error} component="p" />
                        </div>
                        <div>
                            <Field
                                type="email"
                                className={input}
                                name="email"
                                placeholder={t('akcja.gorzka.form.email.placeholder')}
                            />
                            <ErrorMessage name="email" className={error} component="p" />
                        </div>
                        <div className={phoneInput}>
                            <Field
                                type="number"
                                className={input}
                                name="phone"
                                placeholder={t('akcja.gorzka.form.phone.placeholder')}
                            />
                            <ErrorMessage name="phone" className={error} component="p" />
                        </div>
                        <div>
                            <ReactSelect
                                className={select}
                                name={'cityId'}
                                placeholder={t('akcja.gorzka.form.city.placeholder')}
                                onInputChange={popuplateCitiesOptions}
                                onChange={(newValue) => {
                                    if (formRef.current && newValue) {
                                        formRef.current.setFieldValue('cityId', newValue.value);
                                    }
                                }}
                                classNamePrefix={'select'}
                                options={
                                    cities && cities.length > 0 ? getCitiesOptions(cities) : []
                                }
                                noOptionsMessage={() => (
                                    <span>
                                        {t('akcja.gorzka.form.no.cities.1')} <br />
                                        {t('akcja.gorzka.form.no.cities.2')}
                                    </span>
                                )}
                            />
                            <ErrorMessage name="cityId" className={error} component="p" />
                        </div>
                        <div>
                            <Field
                                className={input}
                                name="zipCode"
                                placeholder={t('akcja.gorzka.form.zipCode.placeholder')}
                            />
                            <ErrorMessage name="zipCode" className={error} component="p" />
                        </div>
                        <div>
                            <Field
                                className={input}
                                name="street"
                                placeholder={t('akcja.gorzka.form.street.placeholder')}
                            />
                            <ErrorMessage name="street" className={error} component="p" />
                        </div>
                        <div>
                            <Field
                                className={input}
                                name="apartmentNumber"
                                placeholder={t('akcja.gorzka.form.apartmentNumber.placeholder')}
                            />
                            <ErrorMessage name="apartmentNumber" className={error} component="p" />
                        </div>
                        <div className={full}>
                            <ReactSelect
                                className={select}
                                name={'shopId'}
                                placeholder={t('akcja.gorzka.form.shop.placeholder')}
                                onChange={(newValue) => {
                                    if (formRef.current && newValue) {
                                        formRef.current.setFieldValue('shopId', newValue.value);
                                    }
                                }}
                                classNamePrefix={'select'}
                                options={shops && shops.length > 0 ? getShopsOptions(shops) : []}
                                noOptionsMessage={() =>
                                    cities.length > 0 ? (
                                        t('akcja.gorzka.form.no.shops.3')
                                    ) : (
                                        <span>
                                            {t('akcja.gorzka.form.no.shops.1')} <br />
                                            {t('akcja.gorzka.form.no.shops.2')}
                                        </span>
                                    )
                                }
                            />
                            <ErrorMessage name="shopId" className={error} component="p" />
                        </div>
                    </div>
                    <div className={checkboxes}>
                        <div className={checkboxContainer}>
                            <FormCheckbox
                                textClass={checkboxLabel}
                                className={checkbox}
                                checkmarkClass={checkmark}
                                name="rules1"
                                text={t('akcja.gorzka.form.rules1')}
                                textLink={t('akcja.gorzka.form.rules1.textLink')}
                                link={staticFiles.akcjaGorzkaRegulations}
                                textContinued={t('akcja.gorzka.form.rules1.textContinued')}
                            />
                            <ErrorMessage name="rules1" className={error} component="p" />
                        </div>
                        <div className={checkboxContainer}>
                            <FormCheckbox
                                textClass={checkboxLabel}
                                className={checkbox}
                                checkmarkClass={checkmark}
                                name="rules2"
                                text={t('akcja.gorzka.form.rules2')}
                            />
                            <ErrorMessage name="rules2" className={error} component="p" />
                        </div>
                    </div>
                    <Button isLoading={isLoading} type="submit" className={button}>
                        {t('akcja.gorzka.form.submit')}
                    </Button>
                </Form>
            )}
        </Formik>
    );
};

function getCitiesOptions(cities: { cityId: number; name: string }[]) {
    return cities.map((city) => {
        return { label: city.name, value: city.cityId };
    });
}

function getShopsOptions(shops: { cityId: number; name: string; shopId: number }[]) {
    return shops.map((shop) => {
        return { label: shop.name, value: shop.shopId };
    });
}

export default AkcjaGorzkaForm;
