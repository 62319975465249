import axios from 'axios';
import prependApiUrl from '../utils/prepend-api-url';

import { IAkcjaGorzkaValues } from '../formik/akcja-gorzka-form.config';
import { IAkcjaGorzkaWinnerValues } from '../formik/akcja-gorzka-form-winner.config';

export const akcjaGorzkaFormMutation = (data: IAkcjaGorzkaValues) => {
    return axios.post(prependApiUrl('/akcja-gorzka/create'), {
        ...data,
    });
};

export const postAkcjaGorzkaWinner = (createForm: IAkcjaGorzkaWinnerValues) => {
    return axios.post(prependApiUrl('/akcja-gorzka/addresses'), {
        ...createForm,
    });
};

export const getAkcjaGorzkaCheckToken = (token: string) => {
    return axios.get(prependApiUrl(`/akcja-gorzka/check/${token}`), {});
};

export const getAkcjaGorzkaCities = (query: string) => {
    return axios.get(prependApiUrl(`/search/cities?query=${query}`), {});
};

export const getAkcjaGorzkaShops = (id: string) => {
    return axios.get(prependApiUrl(`/search/shops?cityId=${id}`), {});
};
